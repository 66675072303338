<template>
  <div>
    <header>
      <div class="logo">
        <p class="logoimg"></p>
        <p class="logoname">Oro Préstamo</p>
      </div>

      <nav>
        <a href="#home" @click="hash='home'" :class="hash=='home'?'boxLine':''">Inicio</a>
        <a
          href="#product"
          @click="hash='product'"
          :class="hash=='product'?'boxLine':''"
        >Sobre nosotros</a>
        <a
          href="#privacy"
          @click="hash='privacy'"
          :class="hash=='privacy'?'boxLine':''"
        >Política de privacidad</a>
        <!-- <a href="#features">Características</a> -->
      </nav>
      <div
        class="downLoad"
        @click="download"
        style="float:right;margin:20px 100px 20px 20px"
      >Descargar ahora</div>
    </header>
    <div class="home" id="home">
      <div class="mytitle">
        <div class="title0">Oro Préstamo</div>
        <div
          class="title1"
        >Oro Préstamo es una solicitud de préstamo online cómoda y segura. Todo el proceso de préstamo se puede hacer a través de un teléfono inteligente en cualquier momento y en cualquier lugar.</div>
        <div class="downLoad" @click="download" style="margin-top:80px">Descargar ahora</div>
      </div>
      <p class="rightBox_title">Préstamos rápidos</p>
      <div class="rightBox">
        <div>
          <p>
            Hasta
            <span class="yan">10,000</span>pesos
          </p>
          <p class="min">Desbloqueo de reembolsos</p>
        </div>

        <div>
          <p>
            Completado en
            <span class="yan">24</span> horas
          </p>
          <p class="min">Aprobación rápida</p>
        </div>

        <div>
          <p>
            <span class="yan">0.038%</span>/ día
          </p>
          <p class="min">Tipos de interés bajos</p>
        </div>
      </div>
    </div>
    <div class="product" id="product">
      <p class="title">Sobre nosotros</p>
      <div class="productlist">
        <div class="productitem">
          <img src="../assets/images/1@3x.png" alt />
          <div class="productTitle">Fácil de manejar</div>
          <p class="itemmsg">Siempre que cuentes con una CURP, podrás iniciar tu viaje de préstamo.</p>
        </div>
        <div style="margin: 0 70px" class="productitem">
          <img src="../assets/images/2@3x.png" alt />
          <div class="productTitle">Seguro y fiable</div>
          <p
            class="itemmsg"
          >Protegemos la información personal de todos nuestros usuarios y nunca la revelaremos a terceros sin su permiso.</p>
        </div>
        <div class="productitem">
          <img src="../assets/images/3@3x.png" alt />
          <div class="productTitle">Transferencia rápida de fondos.</div>
          <p
            class="itemmsg"
          >Una vez aprobado, transferiremos el préstamo a su cuenta de ahorro personal en 15 minutos.</p>
        </div>
      </div>
    </div>

    <div class="privacy" id="privacy">
      <p class="title">Política de privacidad</p>
      <div :class="!showmore ? 'privacymsg showmore' : 'privacymsg'">
        <div>

Oro Préstamo fue desarrollado por นาง สุภาพ งามผล.
Esta política de privacidad se aplica al procesamiento de información personal relacionada con nuestros productos o servicios enviados por usted u obtenidos de otra manera. Somos muy conscientes de la importancia que tiene la información personal para usted y estamos comprometidos a proteger su privacidad y garantizar que su información personal alcance el más alto nivel de seguridad. Cumplimos estrictamente con esta política de privacidad y los requisitos de las leyes aplicables en nuestras operaciones comerciales. Antes de utilizar nuestros productos o servicios, lea atentamente esta política de privacidad para evitar cualquier inconveniente en su uso.
<br><br>

Una. La información principal que recopilamos:<br>


1. Información de SMS<br>
Oro Préstamo necesita obtener la información de SMS del usuario (que incluye: título de SMS, contenido de SMS and si leerlo) cuando el usuario pide dinero prestado para evaluar el valor del usuario y realizar un análisis antifraude; Oro Préstamo concede gran importancia a la seguridad de los datos del usuario, y la plataforma cargará los datos del usuario en https://iu.iuuaufbt.com/iuu-gp/, y utilizará el almacenamiento cifrado DES y AES para garantizar la seguridad de los datos. No será compartida con terceros.
<br><br>

2.Información de la lista de contactos <br>
Cuando utiliza Oro Préstamo para solicitar un préstamo, Oro Préstamo necesita seleccionar la información de contacto de emergencia (que incluye: número de contacto, nombre de contacto, relación) en su libreta de direcciones para confirmar su calificación crediticia. para prevenir el riesgo de fraude. Cifraremos y almacenaremos los datos de contacto de emergencia seleccionados para cargarlos con Des y AES, y los cargaremos en https://iu.iuuaufbt.com/iuu-gp/ después del cifrado para garantizar la seguridad de los datos y no se compartirán con terceros. 
<br><br>


3. Información personal<br>
Al enviar una solicitud, debemos recopilar su información de identificación personal, incluido el nombre, la dirección, la fecha de nacimiento, la ocupación y otra información que pueda probar su identidad.     <br><br>

4. Lista de aplicaciones instaladas<br>
Para garantizar la seguridad financiera, es necesario recoger toda la información de instalación de aplicaciones del usuario .
<br><br>

5. La información del dispositivo<br>
Incluye SSAID, dirección MAC, IMEI, versión de hardware, versión de SO, etc.
<br><br>

6. Información de ubicación geográfica<br>
Solo recopilaremos la información de ubicación geográfica del usuario cuando el usuario use la aplicación después de que acepte proporcionar servicios regionales.
<br><br>

7. Permisos de acceso a la cámara<br>
Incluidos los datos del sensor de la cámara para tomar fotografías y reconocimiento facial.
<br><br>

8.Información financiera<br>
Después de que se aprueba la solicitud de préstamo, se debe recopilar la información de la cuenta bancaria del usuario antes de que se pueda emitir el préstamo.
<br><br>

9. Registros de llamadas<br> 
Durante el proceso de registro de usuario, si se utiliza la función de código de verificación de voz, Oro Préstamo necesita tener permiso para acceder a los registros de llamadas del usuario para asegurarse de que la persona que contesta la llamada es él mismo. Prevenir el fraude. Los datos recopilados se cifran y cargan en https://iu.iuuaufbt.com/iuu-gp/. Oro Préstamo nunca compartirá información de los usuarios con terceros para garantizar la seguridad de los datos.   <br><br>


10. Permisos de lectura y escritura del calendario<br> 
Cuando utiliza el servicio de préstamo, puede administrar fácilmente su cronograma de pago, establecer recordatorios de pago y suscribirse a recordatorios de eventos al habilitar los permisos del calendario. Si no habilita este permiso, no podrá usar las funciones relacionadas con la administración del calendario, pero no afectará su uso de otros servicios.
<br><br>



Dos. El propósito de recopilar esta información:<br>
1. Identificar la autenticidad de la identidad del usuario<br>
2. Proteja la seguridad de su cuenta<br>
3. Evite los riesgos potenciales<br>
4. Evalúe su verdadero estado crediticio para que pueda obtener un mejor préstamo
<br><br>

Tres. Cómo usamos esta información:<br>
1. Estamos comprometidos a utilizar su información personal dentro de un rango razonable de acuerdo con las leyes y regulaciones aplicables.<br>

2. Excepto por los requisitos de las leyes para garantizar que el público pueda acceder a ciertos tipos de información, u órdenes judiciales u otros documentos legales que autoricen y tengan derecho a divulgar información personal, no lo divulgaremos, alquilaremos ni compartiremos sin su consentimiento. Información
<br><br>

Cuatro. Almacenamiento y protección de información personal.<br>
Su información personal, como los datos de contacto, los mensajes SMS, etc., se almacenará de forma segura en nuestro centro de datos (https://iu.iuuaufbt.com/iuu-gp/). Con el fin de garantizar la seguridad de sus datos, nos esforzamos por implementar diversas medidas de seguridad, con diversas formas físicas, tecnologías y medidas de control adecuadas, para que sus datos no se difundan, dañen o pierdan. También controlamos estrictamente a los empleados que puedan entrar en contacto con sus datos, incluyendo la implementación de controles y el acceso a diferentes niveles y puestos, firmando contratos confidenciales con estos empleados, monitoreando su trabajo y otros comportamientos. Al mismo tiempo, también proporcionamos un mecanismo de defensa integral para proteger los datos personales de los usuarios de los ataques. Mientras la cuenta del usuario permanezca activa, conservaremos la información personal y la eliminaremos de acuerdo con las leyes y regulaciones aplicables.

<br><br>
Cinco. Control e inspección de información personal<br>
Puede iniciar sesión en su cuenta en cualquier momento y controlar los datos personales de la cuenta correspondiente, y su información no se transferirá a otros sitios web. Tiene derecho a cambiar o eliminar su información personal.También puede tomar la iniciativa de contactarnos (Correo electrónico:  fuhertjh45@gmail.com) para eliminar su información personal.
<br><br>

Seis. Cambios a esta política<br>
Realizaremos cambios a esta política en cualquier momento, ya que el mercado de usuarios es muy amplio y diversificado, si hay algún cambio, nos comunicaremos con usted y el cambio en cuestión entrará en vigencia luego de que sea anunciado. Si continúa utilizando nuestros productos o servicios o utiliza uno de nuestros productos o servicios después del cambio, asumiremos que ha entendido y aceptado el acuerdo después del cambio del tratado.
<br><br>

Siete. Contáctenos<br>
Si tiene alguna pregunta, comentario o solicitud sobre la política de privacidad, comuníquese con nosotros por correo electrónico.<br>
Correo electrónico: fuhertjh45@gmail.com<br>
Dirección: Calle Ferrocarril de Cuernavaca 697, Amp Granada, Miguel Hidalgo, 11529 Ciudad de México, CDMX<br>

</div>


        <p v-show="showmore" @click="showmore = !showmore" class="show"></p>
      </div>
    </div>
    <footer>
      <!-- <p class="title">Contact Us</p> -->
      <div class="contact">
        <!-- <div>
          <img width="25px" src="../assets/images/phone.png" alt />
          5156867204
        </div> -->
        <div>
          <img width="25px" src="../assets/images/email.png" alt />
          fuhertjh45@gmail.com
        </div>
        <div>
          <img width="25px" style="float:left" src="../assets/images/address.png" alt />
          <p style="float:left">
            Calle Ferrocarril de Cuernavaca 697, <br>Amp Granada, Miguel Hidalgo, 11529 Ciudad de México, CDMX
            
          </p>
        </div>
      </div>
      <p class="end">@Copyright by Oro Préstamo</p>
    </footer>
  </div>
</template>
<style lang="less">
.boxLine {
  color: #e25f31;
  // border-bottom: 5px solid #E25F31;
}
.downLoad {
  width: 150px;
  height: 48px;
  background: #e25f31;
  text-align: center;
  line-height: 48px;
  color: #ffffff;
  font-size: 14px;
  cursor: pointer;
  border-bottom-right-radius: 30px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
}

a {
  text-decoration: none;
}
strong {
  font-weight: 600;
}
.title {
  padding: 50px 0;
  font-family: Helvetica-Bold;
  font-size: 24px;
  color: #333333;
  text-align: center;
  font-weight: 600;
}

header {
  width: 100%;
  height: 80px;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 1000000000000;
  line-height: 80px;

  .logo {
    margin-left: 167px;
    float: left;

    .logoimg {
      margin-top: 15px;
      width: 48px;
      height: 48px;
      background: url(../assets/images/logo@3x.png) no-repeat;
      background-size: 100%;
      float: left;
    }

    .logoname {
      font-family: Helvetica-Bold;
      font-size: 14px;
      color: #333333;
      float: left;
      margin-left: 20px;
    }
  }

  nav {
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 167px;

    a {
      margin: 0 40px;
      font-family: Helvetica-Bold;
      font-size: 18px;
      color: #333333;
      text-align: left;
      text-align: center;
      /* border-bottom: 2px solid #fff; */
    }

    a:hover {
      color: #e25f31;
      /* border-bottom: 2px solid rgba(61,76,233); */
      transition: 0.3s;
    }
  }
}

.home {
  background: url(../assets/images/banner.png) no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 600px;
  overflow: hidden;
  margin-top: 70px;
  position: relative;
  img {
    width: 700px;
    height: 100%;
    margin-top: 250px;
    margin-left: 150px;
  }

  .lbr {
    width: 80px;
    height: 16px;
    background-color: #fff;
    margin: 60px 0;
    margin-left: 150px;
  }

  .googlelogo {
    display: block;
    background: url(../assets/images/Googleplay.png) no-repeat;
    background-size: 100%;
    width: 376px;
    height: 112px;
    margin-top: 80px;
    margin-left: 150px;
  }
  .mytitle {
    position: absolute;
    margin-top: 150px;
    margin-left: 150px;
    width: 450px;
    height: 400px;
    background: #ffffff;
    border-radius: 19px;
    padding: 30px;
    .title0 {
      font-size: 38px;
      font-weight: 600;
      color: #000;
      line-height: 40px;
      margin-bottom: 50px;
    }

    .title1 {
      font-size: 16px;
      font-weight: 400;
      color: #000;
      line-height: 30px;
      margin-bottom: 20px;
    }
  }
  .rightBox_title {
    position: absolute;
    right: 450px;
    font-size: 48px;
    color: #fff;
    top: 100px;
  }
  .rightBox {
    position: absolute;
    right: 200px;
    top: 200px;
    font-size: 28px;
    .yan {
      color: #e25f31;
      font-size: 30px;
    }
    .min {
      margin-top: 10px;
      font-size: 14px;
    }
    div {
      margin-bottom: 50px;
    }

    .title {
      font-size: 48px;
      color: #fff;
    }
  }
}

.product {
  .productlist {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    height: 410px;

    .productitem {
      float: left;
      text-align: center;
      width: 460px;
      height: 300px;
      padding: 20px;
      background-color: #fff;
      font-size: 16px;
      border-radius: 30px;
      .productTitle {
        color: #e25f31;
      }
      img {
        width: 100px;
        height: 100px;
        margin-top: 30px;
      }

      .itemmsg {
        font-family: Helvetica;
        font-size: 14px;
        color: #333333;
        text-align: center;
        line-height: 40px;
        margin-top: 10px;
      }
    }
  }
}

.company {
  padding: 0 280px;
  margin-top: 180px;

  .companylist {
    .companyitem {
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 400px;

      .left {
        img {
          width: 586px;
          height: 414px;
        }
      }

      .right {
        .msg {
          max-width: 540px;
          font-family: Helvetica;
          font-size: 20px;
          color: #333333;
          text-align: left;
          line-height: 60px;
        }
      }
    }
  }
}

.features {
  padding: 0 200px;
  margin-top: 180px;

  .companyitem {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 400px;
    margin-bottom: 300px;

    .left {
      img {
        width: 586px;
        height: 414px;
      }
    }

    .right {
      .msg {
        max-width: 800px;
        font-family: Helvetica;
        font-size: 20px;
        color: #666666;
        text-align: left;
        line-height: 60px;
      }
    }
  }
}

.about {
  height: 500px;
  padding: 80px 180px;
  background: #e25f31;
  text-align: center;

  .title {
    font-family: Helvetica-Bold;
    font-size: 44px;
    color: #ffffff;
    padding: 0;
    padding-bottom: 60px;
  }

  .aboutmsg {
    font-family: Helvetica;
    font-size: 24px;
    color: #ffffff;
    line-height: 60px;
    text-align: left;
  }
}

.privacy {
  padding: 0px 210px;
  padding-bottom: 100px;
  position: relative;

  .title {
    font-weight: 600;
    padding: 80px 0;
    font-size: 22px;
  }

  .privacymsg {
    font-family: Helvetica;
    font-size: 20px;
    height: 550px;
    overflow: hidden;
    text-align: left;
    line-height: 50px;

    p {
      font-weight: 600;
      font-size: 20px;
      margin-top: 40px;
      text-align: center;
    }

    .show {
      background: url(../assets/images/getMore.png) no-repeat;
      background-size: 100%;
      width: 60px;
      height: 60px;
      position: absolute;
      top: 750px;
      left: 0;
      right: 0;
      margin: auto;
    }
  }

  .showmore {
    height: auto;
    overflow: auto;
  }
}

footer {
  height: 250px;
  background: #2f2f2f;
  color: #fff;

  .title {
    color: #fff;
    padding: 80px 0;
  }

  .contact {
    padding-top: 30px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    font-size: 18px;
    color: #ffffff;
    text-align: left;

    div {
      font-family: Helvetica;
      font-size: 18px;
      color: #ffffff;
      text-align: left;
      line-height: 31px;
      img {
        margin-right: 5px;
      }
    }
  }

  .end {
    width: 100%;
    text-align: center;
    font-family: Helvetica;
    font-size: 16px;
    color: #ffffff;
    background: #1a1a1a;
    height: 80px;
    line-height: 80px;
    margin-top: 60px;
  }
}
</style>
<script>
export default {
  name: "Home",
  data() {
    return {
      showmore: true,
      hash: window.location.hash,
    }
  },
  methods: {
    download() {
      window.open("https://play.google.com/store/apps/details?id=com.app.lo.go")
    },
  },
}
</script>
